import React from 'react';
import './styles.css';

interface IContact {
  setIsModalOpen: (modalStatus: boolean) => void;
}

export const Contact = ({
  setIsModalOpen
}: IContact) => {
  return (
    <div id='contact' className='section'>
      <div className='contact_content'>
        <h1>No job is too <br /> small.</h1>
        <button onClick={() => setIsModalOpen(true)} className='contact_button'>Get in touch</button>
      </div>
    </div>);
};
