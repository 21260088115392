/**
 * IsValidEmail
 *
 * @param {string} email email address to validate
 * @returns {boolean} true if email is valid, else false
 */
export const isValidEmail = (email: string): boolean =>
  Boolean(email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    ));