import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { isValidEmail } from 'common/util';
import { IContactFormData, IContactFormErrors, IModal } from './types';
import './styles.css';

export const Modal: React.FC<IModal> = ({
  isOpen,
  setIsOpen
}) => {
  const [formData, setFormData] = useState<IContactFormData>({
    name: '',
    emailAddress: '',
    message: ''
  });
  const [formSuccess, setFormSuccess] = useState<boolean>(false);
  const [formErrorText, setFormErrorText] = useState<string>();
  const [formErrors, setFormErrors] = useState<IContactFormErrors>({
    name: false,
    emailAddress: false,
    message: false
  });

  /**
   * HandleSubmit
   *
   * @description Handle contact form submission
   * @async
   * @returns {Promise<void>} Update form success status no return
   */
  const handleSubmit = async (): Promise<void> => {
    if (!formData.name || !formData.message || !formData.emailAddress) {
      setFormErrors({
        name: formData.name == '',
        emailAddress: formData.emailAddress == '',
        message: formData.message == ''
      });
      setFormErrorText('Please fill out the required fields');

      return;
    }

    if (!isValidEmail(formData.emailAddress)) {
      setFormErrors({
        name: false,
        message: false,
        emailAddress: true
      });
      setFormErrorText('Please provide a valid email address');

      return;
    }

    const response = await axios({
      method: 'post',
      url: `https://${process.env.REACT_APP_BASE_URL}/contact.php`,
      headers: { 'content-type': 'application/json' },
      data: formData
    });

    if (response.status === 200) {
      setFormSuccess(true);
    }
  };

  /**
   * HandleOnInputChange
   *
   * @description Handle contact form input onChange event
   * @param {'name' | 'emailAddress' | 'message'} key FormData object key
   * @param {string} value Input field value
   */
  const handleOnInputChange = (key: 'name' | 'emailAddress' | 'message', value: string): void => {
    const updatedFormData = {
      ...formData,
      [key]: value
    };

    setFormData(updatedFormData);
  };

  const handleCloseModal = () => {
    setFormData({
      name: '',
      emailAddress: '',
      message: ''
    });
    setFormErrors({
      name: false,
      emailAddress: false,
      message: false
    });
    setFormErrorText('');

    setIsOpen(false);
  };

  useEffect(() => {
    document.addEventListener('keyup', (e) => {
      if (e.code === 'Escape') {
        handleCloseModal();
      }
    });
  }, []);

  return (
    <div
      className={`modal_bg ${isOpen ? 'active' : ''}`}
      onClick={(event) => {
        const targetElement = event.target as HTMLElement;
        if (targetElement.classList.contains('modal_bg')) {
          handleCloseModal();
        }
      }}
    >
      <div className='modal'>
        {
          formSuccess
            ?
            <div className='contact__successContainer'>
              <button onClick={() => handleCloseModal()} className='cancel_modal_button' />
              <h2>Thanks for getting in touch with us we will get back to you shortly</h2>
            </div>
            :
            <>
              <h2>Get in touch with us</h2>
              <form className='contact_form'>
                <label htmlFor='name'>Name: </label>
                <input
                  type='text'
                  name='name'
                  placeholder='David Lee'
                  required
                  value={formData.name}
                  className={formErrors.name ? 'error' : ''}
                  onChange={e => handleOnInputChange('name', e.currentTarget.value)}
                />

                <label htmlFor='email'>Email: </label>
                <input
                  type='email'
                  name='emailAddress'
                  value={formData.emailAddress}
                  placeholder='example@domain.com.au'
                  className={formErrors.emailAddress ? 'error' : ''}
                  required
                  onChange={e => handleOnInputChange('emailAddress', e.currentTarget.value)}
                />

                <label htmlFor='message'>Message: </label>
                <textarea
                  name='message'
                  id='message'
                  rows={10}
                  value={formData.message}
                  className={formErrors.message ? 'error' : ''}
                  placeholder='Let us know how we can help and we will get in touch'
                  required
                  onChange={e => handleOnInputChange('message', e.currentTarget.value)}
                />

                <span className='error-text'>{formErrorText}</span>

                <button type='button' className='send_modal_button' onClick={handleSubmit}>Send</button>
              </form>
              <button onClick={() => handleCloseModal()} className='cancel_modal_button' />
            </>
        }
      </div>
    </div>
  );
};
